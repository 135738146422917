import DOMPurify from 'isomorphic-dompurify';
import dynamic from 'next/dynamic';
import Image from 'next/future/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { BreadcrumbJsonLd } from 'next-seo';
import { useCallback, useContext, useEffect, useState } from 'react';

import Promotion from '@/components/promotions/promotion';
import HeaderFooterContext from '@/context/HeaderFooterContext';
import { replaceWithRequiredSiteInfo } from '@/helper/wordReplace';
import phoneIcon from '@/public/images/icons/phone-icon.svg';
import searchIcon from '@/public/images/icons/search-icon.svg';
// import imgLink from '@/public/images/logos/access_storage_logo.svg';
import imgLink from '@/public/images/logos/Access-25th-Logo.svg';
import SetBrandsContext from '@/services/home/setBrands';
import {
  BRAND_NAME_EN,
  BRAND_NAME_FR,
  SITE_TITLE,
  SITE_URL,
} from '@/utils/constants';

import SearchInput from '../home-content/search-input';
import { generateDynamicBCLD } from '../utility';

const BreadCrumbs = dynamic(
  () => import('@/components/breadcrumbs/breadcrumb')
);
// This component is the genetic hero image with search input + CTA banner
// TODO: We might want to show the default image if there is no background image provided
const HeroStoreSearchBanner = (props: any) => {
  const router = useRouter();
  const { isBread, cmsData } = props;

  const section = cmsData && cmsData?.attributes && cmsData?.attributes.Section;
  const title = section && section[0] && section[0].Title;
  const subTitle = section && section[0] && section[0].Sub_Title;
  const cta = section && section[1];
  const imgUrl = section && section[0]?.Background_Image.data?.attributes?.url;
  const [isScrolled, setIsScrolled] = useState(false);
  const [scrollText, setScrollText] = useState('sv-page-content scrollText');
  const pathWithoutQuery = isBread && router.asPath.split('?')[0];
  const brandUrl = props?.hostUrl ? `https://${props.hostUrl}` : SITE_URL;
  const brandTitle = SITE_TITLE;
  const { locale }: any = useRouter();
  const [brands, setBrands] = useState<any>([]);
  const getContextData = async () => {
    let defaultBrand = BRAND_NAME_EN;
    if (locale === 'fr') defaultBrand = BRAND_NAME_FR;
    const contextData = await SetBrandsContext(defaultBrand, locale);
    setBrands(contextData?.data);
  };
  useEffect(() => {
    getContextData();
  }, [locale]);
  const handleScrolled = useCallback(
    (e: any) => {
      e.preventDefault();
      const scrollValue = Math.floor(window.scrollY);
      if (scrollValue > 295) {
        setIsScrolled(true);
        setScrollText('sv-page-content scrollText scroll');
      } else {
        setIsScrolled(false);
        setScrollText('sv-page-content scrollText');
      }
    },
    [isScrolled]
  );
  useEffect(() => {
    window.addEventListener('scroll', (e) => handleScrolled(e));
    return () => {
      window.removeEventListener('scroll', () => handleScrolled);
    };
  }, [isScrolled]);

  const { headerFooter } = useContext(HeaderFooterContext);

  const contactNumber = headerFooter[0]?.attributes?.RC_Contact_Number;
  const breadCrumbUrlList = generateDynamicBCLD(router);
  return (
    <>
      <div className={scrollText}>
        <div className="container-xl custom-container px-0">
          <section className="hero-section">
            <div className="hero-banner">
              <Image
                sizes="(max-width: 375px) 320px, (max-width: 576px) 576px, (max-width: 768px) 768px, (max-width: 992px) 992px, (max-width: 1280px) 1280px"
                src={imgUrl || ''}
                alt={
                  section[0]?.Background_Image.data?.attributes
                    ?.alternativeText ||
                  section[0]?.Background_Image.data?.attributes?.name
                }
                height="540"
                width="1000"
                className="banner-img"
                priority={true}
              />
              <div className="banner-content">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 m-auto">
                      <div className="center-box">
                        <h1>{title}</h1>
                        <h6
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              replaceWithRequiredSiteInfo(
                                brandTitle,
                                brandUrl,
                                subTitle
                              )
                            ),
                          }}
                        ></h6>
                      </div>

                      <div className="search-section" id="search-navbar">
                        <div className="container">
                          <div className="d-flex justify-content-between align-items-center m-auto">
                            <div className="item-logo hidden-xxs">
                              <Link href="/">
                                <a className="navbar-brand">
                                  {brands?.length ? (
                                    <Image
                                      src={
                                        brands[0]?.attributes?.Logo?.data
                                          ?.attributes?.url
                                      }
                                      alt="storage-logo"
                                      width="130"
                                      height="60"
                                      priority={true}
                                    />
                                  ) : (
                                    <Image
                                      src={imgLink}
                                      alt="storage-logo"
                                      width="130"
                                      height="60"
                                      priority={true}
                                    />
                                  )}
                                </a>
                              </Link>
                            </div>

                            <div className="search-bar locationSearch">
                              <div>
                                <div className="search-icon">
                                  <Image
                                    src={searchIcon}
                                    alt="search-icon"
                                    priority={true}
                                  />
                                </div>
                                <SearchInput></SearchInput>
                              </div>
                            </div>

                            <div className="item-phone">
                              <a
                                className="phone-link headerPhone"
                                href={`tel:${contactNumber}`}
                              >
                                {contactNumber}
                              </a>
                              <a
                                className="headerPhone phoneIcon"
                                href={`tel:${contactNumber}`}
                              >
                                <Image
                                  src={phoneIcon}
                                  alt="phone-icon"
                                  priority={true}
                                />
                              </a>
                            </div>
                            {/* <div className="item-logo-coc hidden-md">
                              <CanadaLogoLink brands={brands} />
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Promotion
              cta_section={
                brands[0]?.attributes?.CTA_Section.push_brand_cta === true
                  ? brands[0]?.attributes?.CTA_Section
                  : cta
              }
            />
          </section>
        </div>
      </div>
      {isBread && (
        <div className="my-4">
          <BreadCrumbs path={pathWithoutQuery} />
          <BreadcrumbJsonLd itemListElements={breadCrumbUrlList} />
        </div>
      )}
    </>
  );
};

export default HeroStoreSearchBanner;
